import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActionInProgressService } from '../services/action-in-progress.service';
import { map, catchError } from 'rxjs/operators';
import { AppConfigService, COMMS_TOKEN_KEY } from '../services/app-config.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root' })
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private actionInProgressService: ActionInProgressService,
    private configService: AppConfigService,
    private msal: MsalService
  ) { }

  /**
   * Interceptor intercepts every outgoing/incoming http request
   * @param req
   * @param next
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('blob.core.windows.net/docslaid-documents')) {
      req = req.clone({
        headers: new HttpHeaders({
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': 'application/octet-stream'
        })
      });
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.actionInProgressService.actionEnded();
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          const commsApiConfig = this.configService.getValue('CommsEndpoint');

          if (commsApiConfig && error.url.includes(commsApiConfig.url)) {
            localStorage.removeItem(COMMS_TOKEN_KEY);
            this.msal.instance.loginRedirect({scopes: commsApiConfig.scopes});
          }
        }

        this.actionInProgressService.actionEnded();

        return throwError(error);
      }));
  }
}

