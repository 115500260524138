import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ClientFrameworkModule } from 'proceduralsystem-clientcomponents';

import {
  MsalModule,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { MembersPortalAccessCardComponent } from './home/members-portal-access-card/members-portal-access-card.component';
import { AppConfigService } from './shared/services/app-config.service';
import { APP_BASE_HREF } from '@angular/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResponseInterceptor } from './shared/interceptors/response.interceptor';
import { UserAuthInterceptor } from './shared/interceptors/user-auth.interceptor';

import { SignalRService } from './shared/services/signalR.service';
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  InteractionType,
  Configuration,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { ExtendedNotificationTrayComponent } from './shared/components/extended-notification-tray/extended-notification-tray.component';
import { LanguageToggleComponent } from './shared/components/language-toggle/language-toggle.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HttpCacheInterceptor } from './shared/interceptors/app-cache.interceptor';
import { CustomMsalService } from './shared/services/custom-msal.service';

export function msalConfigFactory(
  config: AppConfigService
): Configuration {
  return {
    auth: {
      clientId: config.getValue('msalValues').clientId, // This is the ONLY mandatory field that you need to supply. SPA application Client Id
      authority: config.getValue('msalValues').authority, // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: config.getValue('msalValues').redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
      knownAuthorities: [config.getValue('msalValues').authority],
      postLogoutRedirectUri: config.getValue('msalValues').redirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          // console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: true
      }
    }
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(
  httpClient: HttpClient,
  config: AppConfigService
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    httpClient,
    '/assets/i18n/',
    `.json?v=${config.getValue('VersionNumber')}`
  );
}

export class TranslationHandlerService implements MissingTranslationHandler {
  /**
   * Missing translation handler.
   */
  public handle(params: MissingTranslationHandlerParams): Observable<string> {
    return of(params.key);
  }
}

/**
 * MSAL Angular retrieve tokens for authorizaion
 */
export function MSALInstanceFactory(
  config: AppConfigService
): PublicClientApplication {
  const msalConfig = msalConfigFactory(config);  

  const msalInstance = new PublicClientApplication(msalConfig);
  let isLoginSuccess = false;
  let isLoginFailure = false;

  

  msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // Set active account on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else {
      // handle auth redirect/do all initial setup for msal
      msalInstance.addEventCallback(event => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          isLoginSuccess = true;
          msalInstance.setActiveAccount(
            (event.payload as AuthenticationResult).account
          );
          // window.location.reload();
        }
        if (event.eventType === EventType.LOGIN_FAILURE) {
          isLoginFailure = true;
        }
        if (event.eventType === EventType.HANDLE_REDIRECT_END && isLoginFailure === true && isLoginSuccess !== true) {
          window.location.reload();
        }
      });
    }
  }
})
  return msalInstance;
}

export function MSALInterceptorConfigFactory(
  config: AppConfigService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  let apiEndpoint = config.getValue('ApiEndpoint');
  if (apiEndpoint) {
    protectedResourceMap.set(apiEndpoint.url, apiEndpoint.scopes);
  }
  let commsEndpoint = config.getValue('CommsEndpoint');
  if (commsEndpoint) {
    protectedResourceMap.set(commsEndpoint.url, commsEndpoint.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AppConfigService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: config.getValue('ApiEndpoint').scopes },
  };
}

export function initializeApp(
  config: AppConfigService,
  injector: Injector
): () => Promise<void> {
  return async () => {
    // Fetch the app.config.json at runtime
    await config.init().toPromise();

    // Load translations at startup
    const translateService = injector.get(TranslateService);
    /*
      1. TranslationService needs to be loaded on app startup,
        instant translations are required for navigation init (app.component & DL shared components).
      2. It's AppConfig dependent due to 'AppInitRequest' (user details fetch)
        and 'VersionNumber' (of translation document), so it can't be a separate provider.
      3. Ensuring that primary language translations and secondary language file are fetched, so translations are available.
    */
    await Promise.all([
      translateService.get('en').toPromise(),
      translateService.reloadLang('ga').toPromise(),
    ]).then(() => {
      // The initialization is complete, you can do any additional tasks here if needed.
      // For example, you can resolve a promise or call a callback to indicate completion.
    });
  };
}



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LanguageToggleComponent,
    MembersPortalAccessCardComponent,
    ExtendedNotificationTrayComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationHandlerService,
      },
      defaultLanguage: "en",
      isolate: false,
    }),
    NgxPaginationModule,
    ClientFrameworkModule,
    MsalModule,
  ],
  providers: [
    RouterModule,
    SignalRService,
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: UserAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService, injector: Injector) => initializeApp(config, injector),
      deps: [AppConfigService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    },
    {
      provide: MsalService,
      useClass: CustomMsalService
    },
    MsalGuard,
    MsalBroadcastService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
