import { OnInit, Component } from "@angular/core";
import { ConfigurationService, NavigationService } from "proceduralsystem-clientcomponents";
import { AppConfig, AppConfigService } from "./shared/services/app-config.service";
import { LocaleService } from "./shared/services/locale.service";
import { SignalRService } from "./shared/services/signalR.service";
import { TranslateService } from "@ngx-translate/core";
import { COOKIE_LANGUAGE } from "./shared/constants/shared.constants";
import { REDIRECT_COOKIE_LANGUAGE } from "./shared/constants/general.constant";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  hasCommsToken = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly appConfigService: AppConfigService,
    private readonly signalRService: SignalRService,
    private readonly configurationService: ConfigurationService<AppConfig>,
    readonly localeService: LocaleService,
    readonly internationalisationService: TranslateService
  ) {}

  ngOnInit() {
    const redirectLang = this.configurationService.getCookie(REDIRECT_COOKIE_LANGUAGE);
    const cookieLang = redirectLang || this.configurationService.getCookie(COOKIE_LANGUAGE);

    if (redirectLang) {
      this.configurationService.eraseCookie(REDIRECT_COOKIE_LANGUAGE);
    }

    if (cookieLang) {
      this.toggleLng(cookieLang);
    }

    this.appConfigService.getCommsToken().subscribe(token => {
      this.hasCommsToken = !!token;
      this.initNavigation();
      this.signalRService.createConnection();
    });    
  }

  initNavigation() {
    let tree = [];
    tree.push({ title: this.internationalisationService.instant('NAVIGATION.HOME'), path: "home" });

    this.navigationService.init({
      module: { title: this.internationalisationService.instant("NAVIGATION.MY_ACCOUNT"), path: "" },
      tree,
    });
  }

  toggleLng(locale: string) {
      this.internationalisationService.use(locale);
      this.localeService.lngPreference = locale;
      this.initNavigation();
  }
}
