import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigurationService, OirAuthService, SelectValue } from 'proceduralsystem-clientcomponents';
import { HomeService } from './home.service';
import {
  UserInfo,
  DocumentsLaidRequestAccessModel,
  ApplicationStatus,
  ReferenceData,
  DomainMappings,
  AgencyRefData,
  ValidDomain,
  GenericReferenceModel
} from './home.model';
import { ApplicationsEnum, ApplicationStatusString } from './home.enum';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SingleselectComponent } from 'proceduralsystem-clientcomponents/components/select/singleselect.component';
import { LocaleService } from '../shared/services/locale.service';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { LanguageNames, REQUEST_STATUS_KEY } from '../shared/constants/general.constant';
import { TranslateService } from '@ngx-translate/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../shared/services/app-config.service';

const AGENCY_ORG_TYPE_ID = 4;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren(SingleselectComponent) singleSelects: QueryList<SingleselectComponent>;
  // Shared with template
  docsLaidForm: UntypedFormGroup;
  membersPortalStatus: ApplicationStatus;
  userInfoForm: UntypedFormGroup;
  orgData: Array<SelectValue<any>> = [];
  depsData: Array<SelectValue<any>> = [];
  agenciesData: Array<SelectValue<any>> = [];
  agenciesDataWithDeptId: Array<AgencyRefData> = [];
  applicationsEnum = ApplicationsEnum;
  applicationStatus = ApplicationStatusString;
  docsLaidRequestStatus: string;
  docsLaidSystemUri: string;
  isOnline = false;
  isEdit = false;
  tempUserInfoModel: UserInfo;
  emailDomainInvalid = false;

  // TS file only
  userId: string;
  authTime: number;
  userEmailDomain: string;
  domainMappings: Array<DomainMappings>;
  validDomains: Array<ValidDomain>;
  idTokenProperties = {
    firstName: 'given_name',
    lastName: 'family_name',
    email: 'email',
    userId: 'sub',
    phone: 'telephoneNumber',
    authTime: 'auth_time'
  };
  departmentSubscription: Subscription;
  orgSubscription: Subscription;

  get applicatoinStatusLabel(): string {
    if (!this.docsLaidRequestStatus || !REQUEST_STATUS_KEY[this.docsLaidRequestStatus]) {
      return '';
    }
    return this.internationalisationService.instant(REQUEST_STATUS_KEY[this.docsLaidRequestStatus])
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly homeService: HomeService,
    private readonly route: ActivatedRoute,
    private readonly internationalisationService: TranslateService,
    private readonly localeService: LocaleService,
    private readonly msalService: MsalService,
    private readonly http: HttpClient,
    private readonly config: ConfigurationService<AppConfig>
  ) { }

  ngOnInit() {
    this.docsLaidForm = this.fb.group({
      accessRequestId: 0,
      orgType: [{ value: '', disabled: this.emailDomainInvalid }, Validators.required],
      department: '',
      agency: ''
    });let userObject: any;
  
    userObject = this.msalService.instance.getActiveAccount();
    this.isOnline = userObject !== null;
    const userInfo = {
      firstName: userObject.idTokenClaims?.given_name,
      lastName: userObject.idTokenClaims?.family_name,
      email: userObject.idTokenClaims?.email || '',
      userId: userObject.idTokenClaims?.sub,
      phone: userObject.idTokenClaims?.telephoneNumber
    };
    this.userId = userInfo.userId;
    this.authTime = 0, //+userObject.idToken[this.idTokenProperties.authTime];
    this.userEmailDomain = userInfo.email.substring(
      userInfo.email.lastIndexOf('@') + 1
    );

    this.userInfoForm = this.fb.group({
      firstName: [userInfo.firstName, Validators.required],
      lastName: [userInfo.lastName, Validators.required],
      phone: [userInfo.phone, [Validators.required, Validators.pattern('^\\+(?:[0-9]){1,4} [0-9]{1,15}x?[0-9]{0,5}$')]],
      email: [{ value: userInfo.email, disabled: true }, Validators.required]
    });

    this.checkForUpdatedData();
    let refData: ReferenceData;
    this.http.get<ReferenceData>(`${this.config.getValue('ApiEndpoint').url}/${environment.api.referenceData}`).pipe(take(1)).subscribe(refData => {
      this.domainMappings = refData.domainMappings;
      this.validDomains = refData.validDomains;
      if (this.validDomains.map(x => x.emailDomain).indexOf(this.userEmailDomain) === -1 && this.validDomains.length !== 0) {
        this.emailDomainInvalid = true;
      }
      this.orgData = [];
      this.depsData = [];
      this.agenciesData = [];
      this.agenciesDataWithDeptId = [];
      refData.organisations.forEach(org => {
        this.orgData.push({ value: org.id, title: org.desc });
      });
      refData.departments.forEach(org => {
        this.depsData.push({ value: org.id, title: org.desc });
      });
      refData.agencies.forEach(agency => {
        this.agenciesDataWithDeptId.push(agency);
        this.agenciesData.push({ value: agency.id, title: agency.desc });
      });
      this.initLanguageSubscription();
      this.getApplicationRequests();
    });

    this.orgSubscription = this.docsLaidForm.get('orgType').valueChanges.subscribe(orgType => {
      const agencyCtrl = this.docsLaidForm.get('agency');
      const departmentCtrl = this.docsLaidForm.get('department');

      if (orgType && orgType === AGENCY_ORG_TYPE_ID) {
        if (departmentCtrl.enabled) {
          departmentCtrl.clearValidators();
          departmentCtrl.reset(undefined);
        }

        agencyCtrl.setValidators([Validators.required]);
      } else if (orgType) {
        if (agencyCtrl.enabled) {
          agencyCtrl.clearValidators();
          agencyCtrl.reset(undefined);
        }

        departmentCtrl.setValidators([Validators.required]);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.singleSelects) {
      this.singleSelects.forEach(x => {
        x.dropdown.autoClose = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.departmentSubscription) {
      this.departmentSubscription.unsubscribe();
    }
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
  }

  checkForUpdatedData(): void {
    const formValue = this.userInfoForm.value;
    const storedUserInfo = localStorage.getItem(formValue.email);
    if (storedUserInfo) {
      if (JSON.parse(storedUserInfo).authTime === this.authTime) {
        this.userInfoForm.patchValue({
          firstName: JSON.parse(storedUserInfo).firstName,
          lastName: JSON.parse(storedUserInfo).lastName,
          phone: JSON.parse(storedUserInfo).phone
        });
      } else {
        localStorage.removeItem(formValue.email);
      }
    }
  }

  processDocumentsLaidStatus(docsLaidStatus: ApplicationStatus) {
    this.docsLaidRequestStatus =
      docsLaidStatus === undefined ? '' : docsLaidStatus.status;
    this.docsLaidSystemUri = docsLaidStatus ? `https://${docsLaidStatus.systemUri}` : '';
    this.docsLaidForm.patchValue({
      accessRequestId: docsLaidStatus?.accessRequestId
    });

    if (!docsLaidStatus?.documentsLaidData) {
      this.checkDomainMappings(undefined, undefined, undefined);
    } else {
      let orgId = 0;
      let depId = 0;
      let agencyId = 0;
      orgId = docsLaidStatus?.documentsLaidData.organisationId;
      depId = docsLaidStatus?.documentsLaidData.departmentId;
      agencyId = docsLaidStatus?.documentsLaidData.agencyId;
      this.checkDomainMappings(orgId, depId, agencyId);
    }

    if (
      this.getApplicationStatus(
        this.applicationsEnum.DocumentsLaid,
        this.applicationStatus.Pending
      ) ||
      this.getApplicationStatus(
        this.applicationsEnum.DocumentsLaid,
        this.applicationStatus.Approved
      )
    ) {
      this.docsLaidForm.disable();
    }
  }

  checkDomainMappings(orgId, depId, agencyId): void {
    const domainMaps = this.domainMappings.find(x => x.domain === this.userEmailDomain);
    if (domainMaps) {
      if (domainMaps.organisationId && domainMaps.organisationId !== 0 && !this.applicationStatus.Pending) {
        this.docsLaidForm.get('orgType').patchValue(domainMaps.organisationId);
        this.docsLaidForm.get('orgType').disable();
      } else {
        this.docsLaidForm.get('orgType').patchValue(orgId);
      }

      if (domainMaps.departmentId && domainMaps.departmentId !== 0) {
        this.docsLaidForm.get('department').patchValue(domainMaps.departmentId);
        this.docsLaidForm.get('department').disable();
      } else {
        this.docsLaidForm.get('department').patchValue(depId);
      }

      if (domainMaps.agencyId && domainMaps.agencyId !== 0) {
        this.docsLaidForm.get('agency').patchValue(domainMaps.agencyId);
        this.docsLaidForm.get('agency').disable();
      } else {
        this.docsLaidForm.get('agency').patchValue(agencyId);
      }
    } else {
      this.docsLaidForm.get('orgType').patchValue(orgId);
      this.docsLaidForm.get('department').patchValue(depId);
      this.docsLaidForm.get('agency').patchValue(agencyId);
    }
  }

  getApplicationRequests() {
    this.homeService.getApplicationStatus(this.userId).pipe(take(1)).subscribe(response => {
      const aisLinnStatus : ApplicationStatus = response.find(
        x => {
          return x.id === this.applicationsEnum.MembersPortal;
        }
      );

      if (aisLinnStatus !== undefined) {
        this.membersPortalStatus = {
          "accessRequestId": aisLinnStatus.accessRequestId,
          "name": "Members Portal",
          "systemUri":
            aisLinnStatus.systemUri,
          "id": this.applicationsEnum.MembersPortal,
          "status": aisLinnStatus.status,
        };
      }
      
      const docsLaidStatus : ApplicationStatus = response.find(
        x => {
          return x.id === this.applicationsEnum.DocumentsLaid;
        }
      );
      this.processDocumentsLaidStatus(docsLaidStatus);
    });
  }

  requestAccess(appId: number) {
    // TODO: Validated model
    if (!this.docsLaidForm.invalid) {
      let departmentId = this.docsLaidForm.get('department').value;
      const agencyId = this.docsLaidForm.get('agency').value;
      if (agencyId && agencyId !== 0) {
        departmentId = undefined;
      }
      const model: DocumentsLaidRequestAccessModel = {
        accessRequestId: this.docsLaidForm.get('accessRequestId').value,
        appId,
        userId: this.userId,
        orgId: this.docsLaidForm.get('orgType').value,
        depId: departmentId,
        agencyId: this.docsLaidForm.get('agency').value
      };

      this.homeService
        .requestAccess(model)
        .subscribe(x => this.getApplicationRequests());
    }
  }

  getApplicationStatus(appId: number, status: string): boolean {
    if (this.docsLaidRequestStatus !== undefined && appId === this.applicationsEnum.DocumentsLaid) {
      return this.docsLaidRequestStatus === status;
    }
    return false;
  }

  getAisLinnApplicationStatus(appId: number): boolean {
    if (this.membersPortalStatus !== undefined && appId === this.applicationsEnum.MembersPortal) {
      return this.membersPortalStatus.status === this.applicationStatus.Approved;
    }
    return false;
  }

  editUserInfo() {
    this.tempUserInfoModel = this.userInfoForm.value;
    this.isEdit = true;
    if (!this.userInfoForm.value.phone || this.userInfoForm.value.phone === '') {
      this.userInfoForm.get('phone').patchValue('+353 ');
    }
  }

  cancelUserInfo() {
    this.isEdit = false;
    this.userInfoForm.patchValue(this.tempUserInfoModel);
  }

  updateUserInfo() {
    this.isEdit = false;

    const userInfoModel: UserInfo = this.userInfoForm.value;
    userInfoModel.userId = this.userId;
    const savedVals = {
      authTime: this.authTime,
      firstName: userInfoModel.firstName,
      lastName: userInfoModel.lastName,
      phone: userInfoModel.phone
    };
    this.homeService.updateUserProfile(userInfoModel).subscribe(() => {
      localStorage.setItem(userInfoModel.email, JSON.stringify(savedVals));
    });
  }

  initLanguageSubscription() {
    this.http.get<ReferenceData>(`${this.config.getValue('ApiEndpoint').url}/${environment.api.referenceData}`).pipe(take(1)).subscribe(refData => { 
      this.orgSubscription.add(this.localeService.lngPreference$.pipe(distinctUntilChanged()).subscribe((locale) => {
        this.agenciesData = this.modifyItemToSelectValue(refData.agencies, locale);
        this.depsData = this.modifyItemToSelectValue(refData.departments, locale);
        this.orgData = this.modifyItemToSelectValue(refData.organisations, locale);
      }));
    });
  }

  public modifyItemToSelectValue(items: Array<GenericReferenceModel>, locale = 'en'): Array<SelectValue<any>> {
    const desc = locale === LanguageNames.ga ? 'descGle' : 'desc';
    return (items && items.length > 0) ? (items.map((item: GenericReferenceModel) => ({ value: item.id, title: item[desc] }))) : [];
  }

  get orgSelected(): boolean {
    return this.docsLaidForm.getRawValue().orgType && this.orgData.findIndex(x => x.value === this.docsLaidForm.getRawValue().orgType) > -1;
  }

  get isAgency(): boolean {
    return this.docsLaidForm.getRawValue().orgType === AGENCY_ORG_TYPE_ID;
  }

  get noDepOrAgencySelected(): boolean {
    return !this.docsLaidForm.getRawValue().agency && !this.docsLaidForm.getRawValue().department;
  }
}
