import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { Observable, of, switchMap } from "rxjs";
import { COMMS_TOKEN_KEY } from "./app-config.service";

@Injectable()
export class CustomMsalService extends MsalService {
  override handleRedirectObservable(hash?: string): Observable<AuthenticationResult> {
    return super.handleRedirectObservable(hash).pipe(
      switchMap(res => this._mapCommsApiToken(res))
    );
  }

  override acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> {
    return super.acquireTokenSilent(silentRequest).pipe(
      switchMap(res => this._mapCommsApiToken(res))
    );
  }

  // Msal library v3 issue not receiving multi access tokens when B2C config, in our case Comms API token.
  private _mapCommsApiToken(res: AuthenticationResult): Observable<AuthenticationResult> {
    if (res && !res.accessToken) {
      const commsToken = localStorage.getItem(COMMS_TOKEN_KEY);

      if (commsToken) {
        return of({ ...res, accessToken: commsToken })
      }
    }
    return of(res);
  }
}
